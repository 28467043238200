<template>
  <div>
    <general-table
      ref="configServicesTable"
      api-url="config-services"
      add-type="Add Config Service"
      type="modal"
      :columns="columns"
      :selectable="false"
      add-component-name="add-config-service"
      edit-component="edit-config-service"
      :delete-content="false"
      :view-content="false"
      :search-box="false"
      guard="friends"
    >
      <template #cell(value)="data">
        <b-badge
          :variant="Boolean(data.item.value) ? 'success' : 'danger'"
        >
          {{ Boolean(data.item.value) ? 'Active' : 'Not Active' }}
        </b-badge>
      </template>
    </general-table>
    <div>
      <b-modal
        id="config-service"
        ref="genmodal"
        hide-footer
        centered
        size="lg"
        no-close-on-backdrop
        :title="$store.state.generalIds.id ? 'Update Config Service' : 'Add Config Service'"
      >
        <add-edit-config-service />
      </b-modal>
    </div>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import AddEditConfigService from './control-configService/AddEditConfigService.vue'

export default {
  components: { GeneralTable, AddEditConfigService },
  data() {
    return {
      columns: [
        { key: 'id', sortable: false },
        { key: 'service_name', label: 'Service Name' },
        { key: 'name', label: 'Name' },
        { key: 'service_code', label: 'Service Code' },
        { key: 'value', label: 'Activated' },
        { key: 'actions' },
      ],
    }
  },
}
</script>

<style>

</style>
