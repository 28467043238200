<template>
  <div>
    <validation-observer
      ref="configServicesForm"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="ServiceName"
            rules="required"
          >
            <b-form-group
              label="Service Name"
              label-for="ServiceName"
            >
              <v-select
                id="ServiceName"
                v-model="serviceId"
                label="name"
                :reduce="service => service.id"
                :options="services"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="configServicesForm.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Service Code"
            rules="required"
          >
            <b-form-group
              label="Service Code"
              label-for="ServiceCode"
            >
              <b-form-input
                id="ServiceCode"
                v-model="configServicesForm.service_code"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Service Code"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="IsActive"
            rules="required"
          >
            <b-form-group
              label="Activate"
              label-for="IsActive"
            >
              <b-form-checkbox
                id="IsActive"
                v-model="configServicesForm.value"
                :value="1"
                :unchecked-value="0"
                class="custom-control-secondary"
                name="check-button"
                :state="getValidationState(validationContext)"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="addService"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import axios from 'axios'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import vSelect from 'vue-select'
import configService from '../configService'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      required,
      configServicesForm: {},
      id: this.$store.state.generalIds.id,
      loader: false,
      validationErrors: {},
      errors: {},
      services: [],
    }
  },
  setup() {
    const { getValidationState } = formValidation()

    const { configServicesForm } = configService()

    return {
      getValidationState,
      configServicesForm,
    }
  },
  computed: {
    serviceId: {
      get() {
        if (this.configServicesForm.service_id) {
          return this.configServicesForm.service_id
        }
        return this.services.find(service => service.name === this.configServicesForm.service_name)?.id
      },
      set(id) {
        this.configServicesForm.service_id = id
        this.configServicesForm.service_name = this.services.find(service => service.id === id)?.name
      },
    },
  },
  async mounted() {
    await this.viewConfigService()
    this.getServices()
  },
  methods: {
    closeModal() {
      this.configServicesForm = {
        name: '',
        value: 0,
        service_id: '',
        service_name: '',
        service_code: '',
      }
      this.$store.commit('generalIds/SET_ID', null)
      this.$bvModal.hide('config-service')
    },
    addService() {
      if (this.id) {
        this.loader = true
        axios.put(`config-services/${this.id}`, this.configServicesForm).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$parent.$parent.$parent.$refs.configServicesTable.getAllData()
            this.closeModal()
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        axios.post('config-services', this.configServicesForm).then(res => {
          this.$parent.$parent.$parent.$refs.configServicesTable.getAllData()
          if (res.status === 200 || res.status === 201) {
            this.closeModal()
            this.$toasted.show('Added Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          this.errors = error?.response?.data?.errors
        }).finally(() => {
          this.loader = false
        })
      }
    },
    viewConfigService() {
      if (this.id) {
        axios.get(`config-services/${this.id}`).then(res => {
          if (res.status === 200) {
            this.configServicesForm = res.data?.data
          }
        })
      }
    },
    getServices() {
      axios.get('services').then(res => {
        if (res.status === 200) {
          this.services = res.data?.data
          // Find service id
          this.configServicesForm.service_id = this.services.find(service => service.name === this.configServicesForm.service_name)?.id
        }
      })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
