import { ref } from '@vue/composition-api'

export default function service() {
  const serviceForm = ref({
    service_id: '',
    service_name: '',
    service_code: '',
    name: '',
    value: 0,
  })

  const viewData = ref({})

  return {
    serviceForm,
    viewData,
  }
}
